<script setup lang="ts">
import { useApiClient } from '@/api'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { Button, Card, Row } from '@madxnl/dodo-ui'
import { computed, ref, useSlots } from 'vue'

const props = defineProps<{
  value: unknown
  name: string
  modalTitle: string
}>()

const slots = useSlots()
const { downloadFile, endpoint } = useApiClient()

const showModal = ref(false)

const valueString = computed(() => {
  const value = props.value
  if (typeof value === 'string') return value
  if (isFile.value) return fileOriginalName.value
  if (value == null || typeof value !== 'object') return JSON.stringify(value)
  if (Array.isArray(value)) return JSON.stringify(value, null, 2)

  const flattened = Object.entries(value).reduce(
    (acc, [key, v]) => {
      acc[key] = typeof v === 'object' ? JSON.stringify(v) : v
      return acc
    },
    {} as Record<string, string>,
  )
  return JSON.stringify(flattened, null, 2)
})

const isFile = computed(() => {
  const value = props.value
  return value && typeof value === 'object' && '_modelName' in value && value._modelName === 'file'
})

const fileOriginalName = computed(() => {
  const value = props.value
  return value && typeof value === 'object' && 'originalName' in value ? (value.originalName as string) : 'file'
})

async function clickDownload() {
  const value = props.value
  const location = value && typeof value === 'object' && 'location' in value ? value.location : null
  const downloadLocation = `${endpoint.replace('graphql', 'download')}/${location}`
  const originalName = fileOriginalName.value
  await downloadFile({ downloadLocation, originalName })
}

function openModal() {
  showModal.value = true
}
</script>

<template>
  <div v-if="value == null || value === ''" style="width: 256px" />

  <template v-else>
    <slot name="default" :open-modal="openModal">
      <Card :title="valueString" padding="m" style="width: 256px" hoverable @click="openModal">
        <Row justify="between">
          <small class="dodo-nowrap">
            {{ valueString }}
          </small>

          <Button
            v-if="isFile"
            title="Download file"
            size="s"
            color="primary"
            variant="link"
            square
            @click.stop="clickDownload"
          >
            <TwinIcon icon="Download" size="s" />
          </Button>
        </Row>
      </Card>
    </slot>

    <LargeModalLayout :open="showModal" :title="modalTitle" size-xl @close="showModal = false">
      <template #content>
        <template v-if="slots.modalContent">
          <slot name="modalContent" />
        </template>

        <code v-else>{{ valueString }}</code>
      </template>
    </LargeModalLayout>
  </template>
</template>
