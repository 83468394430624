<script setup lang="ts">
import { RunStatus } from '@/ui/components'
import { useWorkflowDetails } from './composables'

const { workflow } = useWorkflowDetails()
</script>

<template>
  <div v-if="workflow?.latestRun" :class="$style.runErrorStatus">
    <RunStatus :run="workflow.latestRun" prefix="Latest run" />
  </div>
</template>

<style module>
.runErrorStatus {
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
