<script setup lang="ts">
import { useMousePosition } from '@/ui/composables'
import { computed } from 'vue'
import { useDragToConnect, useWorkflowEditor } from '../composables'
import EditorLine from './EditorLine.vue'

const { dragConnectState } = useDragToConnect()
const { editorBlocks } = useWorkflowEditor()
const { mousePosition } = useMousePosition()

const fromBlock = computed(() => dragConnectState.value?.fromBlock)

const points = computed(() => {
  if (!dragConnectState.value) return null
  const { condition, fromInput } = dragConnectState.value
  const fromComponent = editorBlocks.value.find((b) => b.id === fromBlock.value?.id)
  if (!fromComponent) return null
  const connectors = fromInput
    ? [fromComponent.inputConnector!].filter(Boolean)
    : (fromComponent.outputConnectors ?? [])
  const fromConnectorComponent = connectors.find((c) => c.condition === condition) ?? connectors.find((x) => x)
  const from = fromConnectorComponent?.coords
  if (!from) return null

  const x2 = mousePosition.x
  const y2 = mousePosition.y
  const start = { x: from.x, y: from.y, angle: fromInput ? 180 : 0 }
  const end = { x: x2, y: y2, angle: fromInput ? 0 : 180 }
  return { start, end }
})
</script>

<template>
  <EditorLine v-if="dragConnectState && points" :points="points" active />
</template>
