import { type Ref } from 'vue'

export function useCaretPosition(container: Ref<Element | null>) {
  function getCaretPosition() {
    // Get the caret position in the text
    const selection = document.getSelection()
    if (!selection || !container.value) return null
    const { focusNode, focusOffset } = selection
    if (!container.value.contains(focusNode)) return null
    let position = 0
    const childNodes = [...container.value.childNodes] as (Text | Element)[]
    for (const child of childNodes) {
      if (child.contains(focusNode)) {
        position += focusOffset
        break
      }
      position += getNodeTextLength(child)
    }
    return position
  }

  function setCaretPosition(pos: number) {
    // const pos = caretPosition.value
    if (pos == null || !container.value) return
    let currentIndex = 0
    const targetIndex = pos
    const stack: Element[] = [container.value]
    let result: null | { node: Element; offset: number } = null
    while (!result && stack.length > 0) {
      const node = stack.shift()!
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = node.textContent?.length ?? 0
        if (currentIndex + textLength >= targetIndex) {
          // Found the target position within this text node
          const offset = targetIndex - currentIndex
          result = { node, offset }
        }
        currentIndex += textLength
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        stack.unshift(...(Array.from(node.childNodes) as Element[]))
      }
    }
    if (!result) return null
    const range = document.createRange()
    range.setStart(result.node, result.offset)
    range.collapse(true) // Collapse to the caret position

    const selection = window.getSelection()
    if (!selection) return null
    selection.removeAllRanges()
    selection.addRange(range)
  }

  function getNodeTextLength(node: Text | Element) {
    const textLength = node.textContent?.length ?? 0
    return isVar(node) ? textLength + '{{}}'.length : textLength
  }

  function isVar(node: Text | Element) {
    // return 'classList' in node && node.classList.contains(css.var)
    return false
  }

  return { setCaretPosition, getCaretPosition }
}
