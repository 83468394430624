<script setup lang="ts">
import { useDraggable } from '@/ui/composables'
import { computed, ref } from 'vue'
import { useEditorView, useWorkflowEditor } from '../composables'
import EditorBackground from './EditorBackground.vue'

const { handleWheelEvent, viewPosition, zoomScale } = useEditorView()
const { selectedBlock, gridSize } = useWorkflowEditor()

const el = ref<HTMLElement>()
const contentEl = ref<HTMLElement>()
const draggingBackground = ref(false)

const editorTransformCSS = computed(() => {
  const [dx, dy] = viewPosition.value
  const z = zoomScale.value
  const transform = `scale(${z}) translate(${-dx}px, ${-dy}px)`
  return { transform }
})

const dragBackground = useDraggable({
  drag(dx, dy) {
    if (!el.value) return
    const [x, y] = viewPosition.value
    const z = zoomScale.value
    viewPosition.value = [x - dx / z, y - dy / z]
    draggingBackground.value = true
  },
  end() {
    // If the user drags a short distance, treat it as a click and deselect the block
    const hasDragged = dragBackground.distance.value > 10
    if (!hasDragged) {
      selectedBlock.value = undefined
    }
    draggingBackground.value = false
  },
})

function onMouseWheel(e: WheelEvent) {
  handleWheelEvent(e)
}

function onClickBg(e: PointerEvent) {
  dragBackground.startDrag(e)
}

defineExpose({ el, contentEl })
</script>

<template>
  <div
    ref="el"
    :class="[$style.container, draggingBackground && $style.dragging]"
    style="touch-action: none"
    @wheel.prevent="onMouseWheel"
    @pointerdown.left="onClickBg"
  >
    <div ref="contentEl" :class="$style.transformRoot" :style="editorTransformCSS">
      <EditorBackground :grid-spacing="gridSize" />

      <slot />
    </div>
  </div>
</template>

<style module>
.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
  gap: 32px;
  flex-grow: 1;
  overflow: hidden;
  background: var(--grey-1-altbg);
  cursor: grab;
}
.container.dragging {
  cursor: grabbing;
}
.transformRoot {
  transform-origin: 0 0;
  display: flex;
  position: absolute;
  flex-grow: 1;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}
</style>
