<script setup lang="ts">
import type { RunItemFragment } from '@/generated/sdk'
import { RunStatus } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { Chip, type ColorProp, Row } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'

const props = defineProps<{
  run?: RunItemFragment
  prefix?: string
}>()

const showModal = ref(false)

const statusColorProp: Record<RunStatus, ColorProp> = {
  [RunStatus.Done]: 'success',
  [RunStatus.Pending]: 'warning',
  [RunStatus.Running]: 'success',
  [RunStatus.Failed]: 'danger',
}

const label = computed(() => {
  if (props.prefix) return `${props.prefix} ${props.run?.status.toLowerCase()}`
  return props.run?.status
})
</script>

<template>
  <template v-if="run">
    <Chip :color="statusColorProp[run.status]" @click.stop.prevent="() => (showModal = true)">
      <Row>
        {{ label }}
        <TwinIcon v-if="run.status === RunStatus.Failed" icon="Info" style="cursor: pointer" />
      </Row>
    </Chip>
    <template v-if="run.status === RunStatus.Failed">
      <!-- TODO: Move Teleport to Dodo's Modal -->
      <Teleport to="body">
        <LargeModalLayout :open="showModal" title="Run failed" size-xl @close="showModal = false">
          <template #content>
            <code>{{ run.failedReason }}</code>
            <small>
              <code>{{ run.stacktrace }}</code>
            </small>
          </template>
        </LargeModalLayout>
      </Teleport>
    </template>
  </template>
</template>
