<script setup lang="ts">
import { BlockConfigArgumentType, type BlockConfigArgumentFragment } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { useSimpleMessage, useUtils } from '@/ui/composables'
import type { SettingsField } from '@/workflow-edit/sidebar-right/block-sidebar'
import { useManageArguments } from '@/workflow-edit/sidebar-right/block-sidebar'
import { Button, Column, Row } from '@madxnl/dodo-ui'
import { onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue'
import { useCaretPosition, useTemplateParser } from './composables'

const props = defineProps<{
  modelValue: string
  field: SettingsField
  disabled?: boolean
  readonly?: boolean
}>()

const emit = defineEmits<{ 'update:modelValue': [value: string] }>()
const container = useTemplateRef('container')
const manageArgs = useManageArguments()
const { showMessage } = useSimpleMessage()
const { debounce } = useUtils()
const { getCaretPosition, setCaretPosition } = useCaretPosition(container)
const { parseToHtml, getRenamedVars } = useTemplateParser(manageArgs.configArgs)
const updatedArgs = ref<BlockConfigArgumentFragment[]>([])

const templateText = ref(props.modelValue)

onMounted(() => updateHtml(props.modelValue))

onBeforeUnmount(onBlur)

watch(() => props.modelValue, updateHtml)

function updateHtml(newValue: string) {
  if (!container.value) return
  if (newValue !== templateText.value) {
    templateText.value = newValue
    emit('update:modelValue', templateText.value)
  }
  const pos = getCaretPosition()
  const newHtml = parseToHtml(templateText.value)
  if (container.value.innerHTML !== newHtml) {
    container.value.innerHTML = newHtml
    if (pos) setCaretPosition(pos)
  }
}

async function insertAtCaret(text: string) {
  if (!container.value) return
  const current = container.value?.innerText ?? ''
  const pos = getCaretPosition()
  if (pos) {
    const newText = current.slice(0, pos) + text + current.slice(pos)
    updateHtml(newText)
    setCaretPosition(pos + text.length)
  } else {
    const newText = current.trimEnd() + text
    updateHtml(newText)
    setCaretPosition(newText.length)
  }
}

async function clickInsert() {
  const name = window.prompt('Enter the name of the variable', '')
  if (!name) return
  const nameTaken = manageArgs.configArgs.value.find((a) => a.name === name)
  if (nameTaken) return showMessage('Name is already taken', { type: 'danger' })
  if (!manageArgs.canAddExtraArgument.value) throw new Error('Cannot add variables')
  await manageArgs.addExtraArgument(name, BlockConfigArgumentType.Constant)
  container.value?.focus()
  const newSpan = ` {{ ${name} }} `
  await insertAtCaret(newSpan)
}

async function saveUpdatedArgs() {
  for (const arg of new Set(updatedArgs.value)) {
    await manageArgs.saveArgument(arg.name, arg, { name: arg.name })
  }
}

const debouncedSaveArgs = debounce(saveUpdatedArgs)

async function onKeydown(event: KeyboardEvent) {
  if (event.key === 'Tab') {
    event.preventDefault()
    await insertAtCaret('  ')
  } else if (event.key === 'Enter') {
    event.preventDefault()
    await insertAtCaret('\n')
  }
}

async function onInput() {
  const el = container.value!
  const renamed = getRenamedVars(el)
  for (const { argument, newName } of renamed) {
    argument.name = newName
    updatedArgs.value.push(argument)
  }
  if (renamed.length) debouncedSaveArgs.trigger()

  const text = container.value?.innerText ?? ''
  updateHtml(text)
}

async function onBlur() {
  document.getSelection()?.removeAllRanges() // chrome deselect bug workaround
}
</script>

<template>
  <Column gap="xs" grow>
    <Row v-if="!readonly" justify="end" gap="0">
      <Button variant="clear" size="s" square title="Insert variable" :disabled="disabled" @click="clickInsert">
        <TwinIcon icon="Plus" size="s" />
      </Button>
    </Row>

    <div
      ref="container"
      :class="$style.field"
      tabindex="0"
      spellcheck="false"
      :contenteditable="disabled ? 'false' : 'true'"
      @keydown="onKeydown"
      @input="onInput"
      @blur="onBlur"
    ></div>
  </Column>
</template>

<style module>
.field {
  border: 1px solid var(--grey-3-outlines);
  background: var(--grey-0-white);
  padding: 4px 8px;
  border-radius: 8px;
  cursor: text;
  font-size: 16px;
  min-height: 120px;
  line-height: 1.75;
  font-family: monospace;
  white-space: break-spaces;
  overflow: auto;
  scrollbar-width: thin;
}
.field:focus-within {
  border-color: blue;
}
.field span {
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
}
.field [data-var] {
  border-radius: 4px;
  background: var(--grey-2-lines);
  outline: 1px solid var(--grey-3-outlines);
  padding: 2px 0;
  white-space: nowrap;
}
.field [data-invalid] {
  background: var(--negative-light);
  outline: 1px dashed var(--negative);
}
</style>
