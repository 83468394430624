<script setup lang="ts">
import { TabularExportType } from '@/generated/sdk'
import { useRunResults } from '@/runs'
import { TwinIcon } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import { Button, Column, Row, Select } from '@madxnl/dodo-ui'
import { ref } from 'vue'

const { confirmDelete } = useConfirmDelete()
const { showMessage } = useSimpleMessage()

const { selectedRunIds, deleteSelectedRuns, downloadSelectedRuns } = useRunResults()

const exportType = ref<TabularExportType>(TabularExportType.Csv)
const downloadOptions = [
  { label: 'CSV', value: TabularExportType.Csv },
  { label: 'XLSX', value: TabularExportType.Excel },
]

async function clickDelete() {
  if (await confirmDelete('the selected run(s)')) {
    await deleteSelectedRuns()
    showMessage('Runs deleted')
  }
}
</script>

<template>
  <Transition name="slideup">
    <div v-if="selectedRunIds.length" :class="$style.dataactionbar">
      <Row grow :class="$style.page" padding="m" justify="between">
        <Column>
          <p>{{ selectedRunIds.length }} runs selected</p>
        </Column>
        <Row gap="m">
          <Row gap="s">
            <Select
              v-model="exportType"
              placeholder="Enter value"
              :options="downloadOptions"
              style="min-width: 100px"
            />
            <Button @click="() => downloadSelectedRuns(exportType)">Download</Button>
          </Row>
          <Button disabled>Run again</Button>
          <Button square @click="clickDelete">
            <TwinIcon icon="Delete" />
          </Button>
        </Row>
      </Row>
    </div>
  </Transition>
</template>

<style>
.slideup-enter-active,
.slideup-leave-active {
  transition: transform 100ms;
}

.slideup-enter-from,
.slideup-leave-to {
  transform: translateY(100%);
}
</style>

<style module>
.dataactionbar {
  background-color: white;
  position: sticky;
  bottom: 0;
  border-top: 2px solid var(--grey-2-lines);
}

.page {
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  flex-grow: 1;
  z-index: 0;
}
</style>
