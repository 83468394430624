<script lang="ts" setup>
import { Row } from '@madxnl/dodo-ui'
import TwinIcon from './TwinIcon.vue'

const props = defineProps<{
  checked: boolean
  disabled?: boolean
}>()

const emits = defineEmits<{
  (event: 'toggle'): void
}>()

const handleClick = () => {
  if (!props.disabled) {
    emits('toggle')
  }
}
</script>

<template>
  <Row :class="[props.disabled ? $style.disabled : $style.pointer]" @click="handleClick">
    <div :class="[$style.checkMark, props.checked && $style.checked]">
      <TwinIcon v-if="props.checked" icon="Check" />
    </div>
    <slot />
  </Row>
</template>

<style module>
.checkMark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid var(--grey-3-outlines);
  border-radius: 100%;
  background-color: var(--grey-0-white);
  transition: all 50ms;
}

.checkMark:hover {
  border-color: var(--dodo-color-primary);
}

.checked {
  color: var(--grey-0-white);
  border-color: var(--dodo-color-primary);
  background-color: var(--dodo-color-primary);
}

.pointer {
  user-select: none;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}
</style>
