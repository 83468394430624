<script setup lang="ts">
import { LargeModalLayout } from '@/ui/components'
import { Button } from '@madxnl/dodo-ui'

const open = defineModel<boolean>('open', { required: true })
</script>

<template>
  <LargeModalLayout :open="open" title="API documenation" size-xl @close="open = false">
    <template #content>
      <div :class="$style.readme">
        <h1>TwinAI API Documentation</h1>
        <p>
          Welcome to the TwinAI API documentation. This guide provides detailed instructions on how to interact with the
          TwinAI API, including example CURL calls to help you get started quickly.
        </p>
        <br />
        <h2>Base URL</h2>
        <p>All API requests should be made to the following base URL:</p>
        <pre><code>https://api.twinai.nl</code></pre>
        <br />
        <h2>Authentication</h2>
        <p>
          All requests to the TwinAI API require an Authorization header. You should replace
          <code><b>YOUR_API_KEY</b></code> with your actual API key.
        </p>
        <br />
        <h2>Running a Workflow</h2>
        <h3>Start a Workflow Run</h3>
        <p>
          To start a workflow run, you need to make a POST request to the
          "<code>/v1/workflows/<b>WORKFLOW_ID</b>/run</code>" endpoint. Replace <code><b>WORKFLOW_ID</b></code> with
          your actual workflow ID.
        </p>
        <p><strong>Request:</strong></p>
        <pre><code>curl <b>API_URL</b>/v1/workflows/<b>WORKFLOW_ID</b>/run -X POST -d '{&quot;input&quot;: {&quot;input&quot;: &quot;Ate a banana&quot;}}' -H &quot;Content-Type: application/json&quot; -H &quot;Authorization: <b>YOUR_API_KEY</b>&quot;</code></pre>
        <p>
          <strong>Response:</strong> The response will contain the <code>run_id</code> which you will use to poll the
          status of the run.
        </p>
        <pre><code>{
  &quot;id&quot;: &quot;RUN_ID&quot;,
  &quot;status&quot;: 1,
  ...
}
</code></pre>
        <br />
        <h3>Polling the Run Status</h3>
        <p>
          To poll the status of a run, make a GET request to the <code>/v1/runs/{RUN_ID}</code> endpoint. Replace
          <code>{RUN_ID}</code> with the run ID obtained from the previous step.
        </p>
        <p><strong>Request:</strong></p>
        <pre><code>curl $API_URL/v1/runs/RUN_ID -X GET -H &quot;Content-Type: application/json&quot; -H &quot;Authorization: YOUR_API_KEY&quot;
</code></pre>
        <p>
          <strong>Response:</strong> The response will include the status of the run. Status code
          <code>2</code> indicates that the run is completed.
        </p>
        <pre><code>{
  &quot;id&quot;: &quot;RUN_ID&quot;,
  &quot;status&quot;: 2,
  ...
}
</code></pre>
        <br />
        <h3>Example Script for Polling Run Status</h3>
        <p>Below is an example bash script to automate polling the run status until it is completed.</p>
        <pre><code># Set the default API URL
API_URL=&quot;https://api.twinai.nl&quot;
WORKFLOW_ID=&quot;YOUR_WORKFLOW_ID&quot;

# Function to poll the status of a run
function poll_run_status() {
  local run_id=$1
  local status

  while true; do
    # Get the status of the run
    status=$(curl $API_URL/v1/runs/$run_id -X GET -H &quot;Content-Type: application/json&quot; -H &quot;Authorization: YOUR_API_KEY&quot; | jq -r '.status')

    # Check if the status is 2 (completed)
    if [[ $status -eq 2 ]]; then
      break
    fi

    # Sleep for 1 second before polling again
    sleep 1
  done
}

echo &quot;Running workflow&quot;

# Make the first curl request and extract the run ID
run_id=$(curl $API_URL/v1/workflows/$WORKFLOW_ID/run -X POST -d '{&quot;input&quot;: {&quot;input&quot;: &quot;Ate a banana&quot;}}' -H &quot;Content-Type: application/json&quot; -H &quot;Authorization: YOUR_API_KEY&quot; | jq -r '.id')

echo &quot;Run ID: $run_id&quot;

# Poll the status of the run until it is completed
poll_run_status $run_id

# Make the second curl request with the updated run ID
curl $API_URL/v1/runs/$run_id -X GET -H &quot;Content-Type: application/json&quot; -H &quot;Authorization: YOUR_API_KEY&quot; | jq
</code></pre>
        <br />
        <h2>Conclusion</h2>
        <p>
          This documentation covers the essential endpoints and steps to interact with the TwinAI API. By following the
          provided examples, you should be able to start workflows and monitor their status efficiently. For any further
          queries, contact <a href="mailto:support@twinai.nl">support@twinai.nl</a>
        </p>
      </div>
    </template>

    <template #footer="{ close }">
      <Button @click="close()">Close</Button>
    </template>
  </LargeModalLayout>
</template>

<style module>
.readme {
  display: grid;
  gap: 16px;
}
.readme p {
  line-height: 1.6;
}
.readme p {
  line-height: 1.6;
}
.readme pre {
  margin: 0;
  background: var(--grey-0-white);
  padding: 8px;
  border-radius: 2px;
  font-size: 14px;
}
</style>
