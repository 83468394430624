import { useApiClient } from '@/api'
import type { SubscriptionIntervalEnum, SubscriptionLevelEnum } from '@/generated/sdk'
import { PaymentMethodEnum } from '@/generated/sdk'
import { useChargebee } from '@/user'
import type { CardComponent } from '@chargebee/chargebee-js-vue-wrapper'
import { computed, reactive, ref } from 'vue'

type PaymentIntentInput = {
  subscriptionLevel?: SubscriptionLevelEnum
  subscriptionInterval: SubscriptionIntervalEnum
  paymentMethod: PaymentMethodEnum
  cardName?: string
  paymentProvider?: string
}

const creditcardComponent = ref<typeof CardComponent>(null)
const providers = ref()

const providerOptions = computed<{ label: string; value: string }[]>(() =>
  providers.value?.map((provider: any) => ({
    value: provider.id,
    label: provider.name,
  })),
)
const fieldsCompleted = reactive({ number: false, expiry: false, cvv: false })

export function useChargebeePayment() {
  const api = useApiClient()
  const { chargebeeIsLoading, getChargebeeInstance } = useChargebee()

  const validCreditCard = computed(() => {
    // Check if statusses of CreditCardFields are valid
    return fieldsCompleted.cvv && fieldsCompleted.expiry && fieldsCompleted.number
  })

  async function createPaymentIntent(data: PaymentIntentInput): Promise<unknown> {
    if (!data.subscriptionLevel) return

    const { createChargebeePaymentIntent: paymentIntent } = await api.client.createChargebeePaymentIntent({
      subscriptionLevel: data.subscriptionLevel,
      subscriptionInterval: data.subscriptionInterval,
      paymentMethod: data.paymentMethod,
    })
    return paymentIntent
  }

  async function authorizeCreditCardPayment(data: PaymentIntentInput) {
    const paymentIntent = await createPaymentIntent(data)
    const authorizedIntent = await creditcardComponent.value.authorizeWith3ds(paymentIntent, {
      billingAddress: {
        lastName: data.cardName,
      },
    })
    return authorizedIntent.id
  }

  async function authorizeIDealMethod(data: PaymentIntentInput) {
    const instance = await getChargebeeInstance()
    const authorizedIntent = await instance.handlePayment('ideal', {
      paymentIntent: async () => await createPaymentIntent(data),
      paymentInfo: {
        issuerBank: data.paymentProvider,
      },
    })
    return authorizedIntent.id
  }

  async function getAuthorizedPaymentIntentId(data: PaymentIntentInput) {
    if (data.paymentMethod === PaymentMethodEnum.CreditCard) {
      return await authorizeCreditCardPayment(data)
    } else {
      return await authorizeIDealMethod(data)
    }
  }

  function getProperty(obj: unknown, key: string) {
    // Safely get a property from an unknown object
    if (typeof obj === 'object' && obj !== null) {
      return (obj as Record<string, unknown>)[key]
    }
  }

  async function fetchMollieProviders() {
    const { getMolliePaymentMethods } = await api.client.getMolliePaymentMethods()
    const firstMethod = getProperty(getMolliePaymentMethods, '0')
    const issuers = getProperty(firstMethod, 'issuers')
    providers.value = issuers
  }

  return {
    chargebeeIsLoading,
    getAuthorizedPaymentIntentId,
    creditcardComponent,
    fieldsCompleted,
    fetchMollieProviders,
    providerOptions,
    validCreditCard,
  }
}
