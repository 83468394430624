import { MODE_DEV } from '@/environment'
import Hotjar from '@hotjar/browser'
import type { App } from 'vue'

export function hotjarPlugin(app: App) {
  if (!MODE_DEV) {
    const siteId = 5202898
    const hotjarVersion = 6

    Hotjar.init(siteId, hotjarVersion)
  }
}
