import { markRaw, ref } from 'vue'
import type { NavEntry } from '../components'

const navEntries = ref<NavEntry[]>([])

export function useNavEntries() {
  function addNavEntry(item: NavEntry) {
    if ('component' in item) {
      item.component = markRaw(item.component as object)
    }
    navEntries.value.push(item)
    navEntries.value.sort((a, b) => (a.order || 0) - (b.order || 0))
  }

  return { navEntries, addNavEntry }
}
