import { getVar } from '@/environment'
import { getSdk } from '@/generated/sdk'
import { GraphQLClient } from 'graphql-request'
import { computed, ref } from 'vue'

const endpoint = getVar('VITE_APP_API_URL')
const authToken = ref('')

const headers = computed(() => ({
  'X-Authorization-Method': 'jwt',
  Authorization: `Bearer ${authToken.value}`,
}))

const graphql = new GraphQLClient(endpoint)
graphql.setHeaders(headers.value)

const client = getSdk(graphql)

function nullifyEmptyStr<T extends Record<string, unknown>>(obj: T) {
  const result: Record<string, unknown> = {}
  for (const key in obj) {
    const value = obj[key]
    result[key] = value === '' ? null : value
  }
  return result as { [K in keyof T]: T[K] extends string ? string | null : T[K] }
}

function setAccessToken(token: string) {
  authToken.value = token
  graphql.setHeaders(headers.value)
}

export function useApiClient() {
  async function uploadFile(file: File) {
    const originalName = file.name
    const response = await client.createFile({ input: { originalName } })
    const uploadLocation = response.createFile.uploadLocation
    if (!uploadLocation) throw new Error('Upload location not found')

    const body = new FormData()
    body.append('file', file)
    const response2 = await fetch(uploadLocation, { method: 'POST', body, headers: headers.value })
    if (!response2.ok) throw new Error('Upload failed')

    return response.createFile
  }

  async function downloadFile(file: { downloadLocation?: string | null; originalName?: string | null }) {
    const { downloadLocation } = file
    if (!downloadLocation) throw new Error('Download location not found')
    const response2 = await fetch(downloadLocation, { headers: headers.value })
    if (!response2.ok) throw new Error('Download failed')

    // Save the file using an anchor element so we can set the download filename
    const blob = await response2.blob()
    const anchor = document.createElement('a')
    if (file.originalName) anchor.download = file.originalName
    anchor.href = window.URL.createObjectURL(blob)
    anchor.click()
  }

  return { graphql, client, nullifyEmptyStr, setAccessToken, uploadFile, downloadFile, endpoint, headers }
}
