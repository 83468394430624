<script setup lang="ts">
import { LargeModalLayout } from '@/ui/components'
import { useWorkflowDetails } from '@/workflow-edit'
import { Button, Column } from '@madxnl/dodo-ui'

const { updateWorkflow } = useWorkflowDetails()

const open = defineModel<boolean>({ required: true })

async function handleMakePublic() {
  await updateWorkflow({ isPublic: true })
  open.value = false
}
</script>

<template>
  <LargeModalLayout :open="open" title="Access to workflow" @close="open = false">
    <template #content>
      <Column gap="l" align="center">
        <Column align="center">
          <h2>WARNING</h2>
          <p :class="$style.alertText">
            By clicking the button below, you will make this workflow publicly accessible, allowing anyone to run it.
          </p>
        </Column>
        <Button color="danger" variant="solid" @click="handleMakePublic">Make publicly accessible</Button>
      </Column>
    </template>
  </LargeModalLayout>
</template>

<style module>
.alertText {
  text-align: center;
  font-weight: var(--dodo-weight-bold);
}
</style>
