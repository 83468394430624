<script lang="ts" setup>
import { CheckMark, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { onMounted, onUnmounted } from 'vue'
import ChooseInputData from './ChooseInputData.vue'
import RunWorkflowSettings from './RunWorkflowSettings.vue'
import { useRunWorkflowForm } from './useRunWorkflowForm'

const { showMessage } = useSimpleMessage()

const runForm = useRunWorkflowForm()
const { data, inputNames, availableInputDatas, errors, runWorkflowDetails, initForm, submitRun, loadInputData, clear } =
  runForm

const props = defineProps<{
  workflowId: string
  useInputData?: boolean
  showWorkflowInfo?: boolean
}>()

const emit = defineEmits<{
  running: []
}>()

onMounted(init)
onUnmounted(clear)

async function init() {
  if (!props.workflowId) return
  await initForm({ workflowId: props.workflowId, useInputData: props.useInputData })

  if (runWorkflowDetails.value?.draft) {
    return showMessage('This workflow is currently in draft mode. Please publish it before running it.', {
      type: 'danger',
      duration: 10000,
    })
  }
}

function selectInputData(id: string) {
  const input = availableInputDatas.value?.find((input) => input.id === id)
  if (!input) return
  data.selectedInputDataId = id
  loadInputData(input)
}

async function handleRunWorkflow() {
  const response = await submitRun()
  if (!response) return // Validation failed
  showMessage('Running workflow')
  emit('running')
}

function toggleSaveData() {
  data.createNewInputData = !data.createNewInputData
}
</script>

<template>
  <template v-if="showWorkflowInfo">
    <h1>{{ runWorkflowDetails?.name }}</h1>
    <p>{{ runWorkflowDetails?.description }}</p>
    <hr />
  </template>

  <template v-if="useInputData && availableInputDatas && availableInputDatas.length > 0">
    <FormItem label="Previous input data" optional>
      <ChooseInputData :value="data.selectedInputDataId" :options="availableInputDatas" @select="selectInputData" />
    </FormItem>
    <hr />
  </template>

  <template v-if="inputNames.length > 0">
    <RunWorkflowSettings :run-form="runForm" />
  </template>
  <template v-else>
    <p>No workflow input arguments detected. Please add them in the editor.</p>
  </template>

  <template v-if="useInputData">
    <hr />
    <FormItem @click="toggleSaveData">
      <Row>
        <CheckMark :checked="data.createNewInputData">Store this data for reuse in the future</CheckMark>
      </Row>
    </FormItem>
    <FormItem v-show="data.createNewInputData" label="Input data name" :error="errors.name">
      <TextInput v-model="data.name" placeholder="Choose a name for this input data" />
    </FormItem>
  </template>

  <Row justify="end">
    <Button color="primary" variant="solid" :disabled="runWorkflowDetails?.draft" @click="handleRunWorkflow">
      <TwinIcon icon="Play" />
      Run now
    </Button>
  </Row>
</template>
