<script setup lang="ts">
import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { computed } from 'vue'
import { useWorkflowDetails, useWorkflowEditor } from '../composables'
import { EditorLine } from '../connect'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment
  nextBlockId: string
  disabled: boolean
}>()

const { deleteConnection } = useWorkflowDetails()
const { getBlock, editorBlocks } = useWorkflowEditor()

const fromId = computed(() => props.workflowBlock.id)
const toId = computed(() => props.nextBlockId)

const points = computed(() => {
  const condition = getBlock(toId.value)?.condition ?? undefined
  const fromComponent = editorBlocks.value.find((b) => b.id === fromId.value)
  const toComponent = editorBlocks.value.find((b) => b.id === toId.value)
  const outputConnectors = fromComponent?.outputConnectors ?? []
  // Connector might not match condition because there can be multiple connections and only one condition is stored on target block
  const fromConnector = outputConnectors.find((c) => c.condition === condition) ?? outputConnectors.find((c) => c)
  const toConnector = toComponent?.inputConnector
  const from = fromConnector?.coords
  const to = toConnector?.coords
  if (!from || !to) return null
  const startAngle = fromConnector!.connectAngle
  const endAngle = toConnector!.connectAngle
  const start = { x: from.x, y: from.y, angle: startAngle }
  const end = { x: to.x, y: to.y, angle: endAngle }
  return { start, end }
})

async function clickLine() {
  if (props.disabled) return
  await deleteConnection(props.workflowBlock, { id: toId.value })
}
</script>

<template>
  <EditorLine v-if="points" :points="points" :disabled="disabled" @click="clickLine" />
</template>
