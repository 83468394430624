import type { BlockConfigArgumentFragment } from '@/generated/sdk'
import type { Ref } from 'vue'

// Handlebars Template parsing

type NodeVar = {
  type: 'var'
  name: string
  invalid: boolean
  argument: BlockConfigArgumentFragment | null
}
type NodeText = {
  type: 'text'
  text: string
}
type Node = NodeVar | NodeText
type TemplateTree = {
  children: Node[]
}

export function useTemplateParser(args: Ref<BlockConfigArgumentFragment[]>) {
  function parseToHtml(templateText: string) {
    // Convert text to html with handlebars highlighted
    const tree = parse(templateText)
    return toHtml(tree)
  }

  function getArgByName(name: string) {
    return args.value.find((a) => a.name === name) ?? null
  }

  function parse(templateText: string): TemplateTree {
    const children: Node[] = []
    let textRemain = templateText
    while (textRemain.length) {
      const handlebar = parseHandlebar(textRemain)
      if (handlebar) {
        const textBefore = textRemain.slice(0, handlebar.index)
        if (textBefore) children.push({ type: 'text', text: textBefore })
        const handlebarString = handlebar.content
        const handlebarWords = handlebarString.trim().split(/\s+/)
        const name = handlebarWords[handlebarWords.length - 1]
        const argument = name ? getArgByName(name) : null
        const invalid = !argument
        children.push({ type: 'var', name: handlebarString, invalid, argument })
        const indexAfter = handlebar.index + handlebar.length
        textRemain = textRemain.slice(indexAfter)
      } else {
        children.push({ type: 'text', text: textRemain })
        break
      }
    }
    return { children }
  }

  function toHtml(tree: TemplateTree) {
    let html = ''
    for (const node of tree.children) {
      if (node.type === 'var') {
        const noarg = node.invalid ? `data-invalid` : ''
        const argname = node.argument ? `data-argument="${node.argument.name}"` : ''
        html += `<span data-var ${noarg} ${argname}>{{${node.name}}}</span>`
      } else {
        html += node.text
      }
    }
    if (!html.endsWith('\n')) html += '\n' // chrome newline bug workaround
    return html
  }

  function getRenamedVars(container: Element) {
    const argSpans = container.querySelectorAll('[data-argument]')
    const renamedVars: { argument: BlockConfigArgumentFragment; newName: string }[] = []
    for (const span of argSpans) {
      const originalName = span.getAttribute('data-argument')!
      const argument = getArgByName(originalName)
      if (!argument) continue
      const handlebar = parseHandlebar(span.textContent ?? '')
      if (!handlebar) continue
      const newName = handlebar.name
      if (newName === originalName) continue
      if (newName) renamedVars.push({ argument, newName })
    }
    return renamedVars
  }

  function parseHandlebar(text: string) {
    const handlebarRegex = /{{ *[^{} ]+( +[^{} ]+)* *}}/
    const handlebarFound = text.match(handlebarRegex)
    if (!handlebarFound) return null
    const full = handlebarFound[0]
    const content = full.slice(2, -2)
    const handlebarWords = content.trim().split(/\s+/)
    const name = handlebarWords[handlebarWords.length - 1] ?? ''
    const argument = name ? getArgByName(name) : null
    const invalid = !argument
    const index = handlebarFound.index!
    const length = full.length
    return { name, argument, invalid, content, index, length }
  }

  return { parseToHtml, getRenamedVars }
}
