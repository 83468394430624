<script lang="ts" setup>
import { LargeModalLayout } from '@/ui/components'
import { workflowLinks } from '@/workflows/routes'
import { useRouter } from 'vue-router'
import RunWorkflowForm from './RunWorkflowForm.vue'

const router = useRouter()
const props = defineProps<{
  workflowId: string
  open: boolean
}>()

defineEmits<{
  close: []
}>()

async function handleRunWorkflow() {
  await router.push(workflowLinks.workflowData(props.workflowId))
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run workflow" @close="$emit('close')">
    <template #content>
      <RunWorkflowForm :workflow-id="workflowId" use-input-data @running="handleRunWorkflow" />
    </template>
  </LargeModalLayout>
</template>
