import type { WorkflowBlockItemFragment } from '@/generated/sdk'
import { WorkflowBlockType } from '@/generated/sdk'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { ref } from 'vue'

const dragConnectState = ref<{
  fromBlock: WorkflowBlockItemFragment
  fromInput: boolean
  condition: undefined | string
}>()

export function useDragToConnect() {
  // Connect blocks by dragging a line from one block to another

  const { connectBlocks } = useWorkflowDetails()

  function beginDragConnect(props: { fromBlock: WorkflowBlockItemFragment; isInput: boolean; condition?: string }) {
    dragConnectState.value = {
      fromInput: props.isInput,
      condition: props.condition,
      fromBlock: props.fromBlock,
    }
    document.addEventListener('pointerup', pointerup)
  }

  function pointerup() {
    document.removeEventListener('pointerup', pointerup)
    dragConnectState.value = undefined
  }

  async function endDragConnect(workflowBlock: WorkflowBlockItemFragment) {
    // Release drag connect on top of this block
    if (!dragConnectState.value) return
    const { fromBlock, condition, fromInput } = dragConnectState.value
    dragConnectState.value = undefined
    const isIfElse = workflowBlock.blockType === WorkflowBlockType.IfElse
    if (fromBlock.id === workflowBlock.id) return
    if (fromInput) {
      const condition = isIfElse ? 'true' : undefined
      await connectBlocks(workflowBlock, { id: fromBlock.id, condition })
    } else {
      await connectBlocks(fromBlock, { id: workflowBlock.id, condition })
    }
  }

  return { dragConnectState, beginDragConnect, endDragConnect }
}
