<script setup lang="ts">
import { useApiClient } from '@/api'
import type { NavEntry } from '@/navigation/components'
import { Navbar } from '@/navigation/components'
import { TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { workflowLinks } from '@/workflows'
import RunWorkflowModal from '@/workflows/runworkflow/RunWorkflowModal.vue'
import { Button, Column, Dropdown, Icon, Row, Spinner, Tooltip } from '@madxnl/dodo-ui'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import Editor from './Editor.vue'
import { useEditorState, useEditorView, useWorkflowAutoLayout, useWorkflowDetails } from './composables'

defineProps<{
  workflowId: string
}>()

const { workflow } = useWorkflowDetails()
const router = useRouter()
const { showMessage } = useSimpleMessage()
const { centerView } = useEditorView()
const { recalculateLayout } = useWorkflowAutoLayout()
const { getState } = useEditorState()

const runWorkflowModalOpen = ref(false)

const navEntries: NavEntry[] = [
  { position: 'start', isSeparator: true },
  { position: 'start', slot: 'exit' },
  { position: 'end', slot: 'more' },
  { position: 'end', slot: 'draft' },
  { position: 'end', slot: 'run' },
  { position: 'end', slot: 'results' },
]
watch(() => workflow.value?.id, setLayoutIfNeeded, { immediate: true })

async function setLayoutIfNeeded() {
  // Check if the workflow needs a layout update
  if (workflow.value && !getState('blockPositions')) {
    await recalculateLayout()
  }
}

async function clickExit() {
  await router.push(workflowLinks.workflows())
}

const { client } = useApiClient()

async function setToDraft() {
  await client.setToDraft({ workflowId: workflow.value!.id })
  workflow.value!.draft = true
  showMessage('Workflow set to draft')
}

async function setToPublished() {
  await client.setToPublished({ workflowId: workflow.value!.id })
  workflow.value!.draft = false
  showMessage('Workflow set to published')
}

async function clickAutolayout() {
  await recalculateLayout()
}

function clickCenterView() {
  centerView()
}

function onMouseWheel(e: WheelEvent) {
  const isPinchZoom = e.ctrlKey && !Number.isInteger(e.deltaY)
  if (isPinchZoom) {
    e.preventDefault()
  }
}
</script>

<template>
  <div :class="$style.EditorPage" @wheel="onMouseWheel">
    <Navbar :nav-entries="navEntries" gap="m">
      <template #exit>
        <Row gap="xs">
          <Button variant="link" @click="clickExit"> <TwinIcon icon="ChevronLeft" />Return to overview</Button>
        </Row>
      </template>

      <template #more>
        <Dropdown v-if="workflow?.draft" class="navbar-dropdown">
          <template #trigger="{ toggle }">
            <Button square @click="toggle">
              <TwinIcon icon="More" />
            </Button>
          </template>
          <template #content>
            <Button
              variant="clear"
              :disabled="!workflow?.draft"
              :class="$style.dropdownButton"
              @click="clickAutolayout"
            >
              <Icon name="Schema" /> Autolayout
            </Button>
            <Button variant="clear" :class="$style.dropdownButton" @click="clickCenterView()">
              <Icon name="Recenter" /> Center view
            </Button>
          </template>
        </Dropdown>
      </template>

      <template #draft>
        <Button v-if="!workflow?.draft" @click="setToDraft"> <TwinIcon icon="Edit" /> Edit </Button>
        <Button v-if="workflow?.draft" variant="solid" color="primary" @click="setToPublished">
          <Icon name="Publish" /> Publish
        </Button>
      </template>

      <template #run>
        <Tooltip v-if="workflow?.draft" text="Workflow is not published yet">
          <Button variant="solid" color="secondary" @click.prevent> <TwinIcon icon="Play" /> Run </Button>
        </Tooltip>
        <Button v-else variant="solid" :disabled="workflow?.draft" color="primary" @click="runWorkflowModalOpen = true">
          <TwinIcon icon="Play" /> Run
        </Button>
      </template>

      <template #results>
        <Button @click="router.push(workflowLinks.workflowData(workflowId))">
          <TwinIcon icon="NPS" />View results
        </Button>
      </template>
    </Navbar>

    <Editor v-if="workflow" />
    <Column v-else align="center" justify="center" style="justify-content: center; flex: 1">
      <Spinner large />
    </Column>

    <RunWorkflowModal
      v-if="workflow"
      :open="runWorkflowModalOpen"
      :workflow-id="workflow.id"
      @close="runWorkflowModalOpen = false"
    />
  </div>
</template>

<style module>
.EditorPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  touch-action: none;
}

.separator {
  background: red;
  height: 2px;
  border-radius: 1px;
  background: var(--grey-2-lines);
  width: 100%;
  display: inline-block;
}

.content {
  margin-top: 10px;
}

.dropdownButton {
  justify-content: flex-start;
}
</style>
