<script setup lang="ts">
import type { BlockItemFragment, WorkflowBlockItemFragment } from '@/generated/sdk'
import { useAnimationFrame } from '@/ui/composables'
import { computed, ref } from 'vue'
import { useDragToConnect, useWorkflowEditor } from '../composables'
import ChooseBlockReview from './ChooseBlockReview.vue'
import ChooseConnectorAction from './ChooseConnectorAction.vue'
import EditorBlockConnectorButton from './EditorBlockConnectorButton.vue'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment | null
  isInput?: boolean
  isSwitch?: boolean
  disabled: boolean
  condition?: string
  isDataReview?: boolean
  hoverState: boolean
}>()

const { beginDragConnect, dragConnectState } = useDragToConnect()
const { addNewBlock } = useWorkflowEditor()
const { onAnimationFrame } = useAnimationFrame()

const button = ref<InstanceType<typeof EditorBlockConnectorButton>>()

const isDragConnectTarget = computed(() => {
  if (!dragConnectState.value) return null
  if (dragConnectState.value.fromBlock.id === props.workflowBlock?.id) return false
  return dragConnectState.value.fromInput !== props.isInput
})

const coords = ref({ x: 0, y: 0 })
const menuOpen = ref(false)

onAnimationFrame(updateCoords)

function updateCoords() {
  if (!button.value?.bullet) return
  const rect = button.value.bullet.getBoundingClientRect()
  const x = rect.left + rect.width / 2
  const y = rect.top + rect.height / 2
  coords.value = { x, y }
}

async function addBlock(blockItem: BlockItemFragment) {
  if (props.disabled || !props.workflowBlock) return

  const blockType = blockItem.workflowBlockType
  const blockConfigBlock = blockItem.id

  if (props.isInput) {
    await addNewBlock({ blockType, blockConfigBlock, nextBlocks: [{ id: props.workflowBlock.id }] }, { select: true })
  } else {
    const condition = props.condition ?? undefined
    await addNewBlock(
      { blockType, blockConfigBlock, previousBlocks: [{ id: props.workflowBlock.id }], condition },
      { select: true },
    )
  }
}

function onDragStart() {
  menuOpen.value = false
  if (props.disabled || props.isDataReview || !props.workflowBlock) return

  beginDragConnect({
    condition: props.condition,
    fromBlock: props.workflowBlock,
    isInput: props.isInput,
  })
}

const connectAngle = computed(() => {
  return props.isInput ? 180 : props.isSwitch ? -90 : 0
})

const condition = computed(() => props.condition)
const isInput = computed(() => props.isInput)

defineExpose({ connectAngle, condition, isInput, coords })
</script>

<template>
  <div :class="$style.anchor">
    <template v-if="isDataReview">
      <ChooseBlockReview
        :workflow-block="workflowBlock"
        :is-input="isInput"
        :condition="condition"
        :hover-state="hoverState"
      />
    </template>

    <template v-else>
      <ChooseConnectorAction v-model:open="menuOpen" @select-block-type="addBlock">
        <EditorBlockConnectorButton
          ref="button"
          :disabled="disabled"
          icon="Plus"
          :hover-state="isDragConnectTarget ?? hoverState"
          @pointerdown.stop="onDragStart"
          @click.prevent="menuOpen = true"
        />
      </ChooseConnectorAction>
    </template>
  </div>
</template>

<style module>
.anchor {
  position: relative;
  background: red;
}
</style>
