import type { App } from 'vue'
import type { Router } from 'vue-router'
import PageNotFoundRedirect from './components/PageNotFoundRedirect.vue'
import { useNavEntries } from './composables'

export const navigation = {
  install: (app: App, { router }: { router: Router }) => {
    // Register auth routes
    router.addRoute({ path: '/:pathMatch(.*)*', component: PageNotFoundRedirect })
  },
  navEntries: useNavEntries(),
}

export type Navigation = typeof navigation
