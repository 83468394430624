import { ref } from 'vue'

export function usePolling(fetchData: () => Promise<unknown>) {
  const polling = ref(false)
  const time = 1000

  async function startPolling() {
    if (polling.value) return
    polling.value = true as boolean

    while (polling.value) {
      await fetchData()
      await new Promise((resolve) => setTimeout(resolve, time))
    }
  }

  function stopPolling() {
    polling.value = false
  }

  return { startPolling, stopPolling }
}
