<script setup lang="ts">
import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import type { ApiKeyFragment } from '@/generated/sdk'
import { DisplayDate, LargeModalLayout, TwinIcon } from '@/ui/components'
import { useConfirmDelete, useSimpleMessage } from '@/ui/composables'
import { Button, Card, Column, FormItem, Grid, Icon, Row, Spinner, TextInput } from '@madxnl/dodo-ui'
import { onMounted, reactive, ref } from 'vue'
import OrganizationApiReadme from './OrganizationApiReadme.vue'

const apiKeys = ref<ApiKeyFragment[]>()
const { client } = useApiClient()

onMounted(fetchKeys)

async function fetchKeys() {
  const res = await client.apiKeys()
  apiKeys.value = res.apiKey
}

const { showMessage } = useSimpleMessage()
const { confirmDelete } = useConfirmDelete()
const { currentUser } = useCurrentUser()

async function copyApiKey(apiKey: string) {
  await navigator.clipboard.writeText(apiKey)
  showMessage('API key copied to clipboard')
}

async function clickDelete(key: ApiKeyFragment) {
  const confirmed = await confirmDelete('API key')
  if (!confirmed) return
  await client.deleteApiKey({ apiKeyId: key.id })
  showMessage('API key deleted')
  await fetchKeys()
}

const createKeyOpen = ref(false)
const readmeOpen = ref(false)

const createData = reactive({
  name: '',
})

async function clickCreate() {
  const name = createData.name
  const organizationId = currentUser.value!.organization.id
  await client.createApiKey({ input: { name, organization: { id: organizationId } } })
  createKeyOpen.value = false
  showMessage('API key created')
  await fetchKeys()
}
</script>

<template>
  <template v-if="apiKeys">
    <Grid v-if="apiKeys.length" column-size="m">
      <Card v-for="key in apiKeys" :key="key.id" gap="s">
        <Row>
          <Column gap="xs" grow>
            <h4 v-if="key.name">{{ key.name }}</h4>
            <h4 v-else class="dodo-fade-secondary">Unnamed key</h4>
            <p>{{ key.apiKey }}</p>
            <p class="form-description"><DisplayDate :date="parseInt(key.updatedAt)" /></p>
          </Column>

          <Button variant="link" square title="Copy API key to clipboard" @click="copyApiKey(key.apiKey)">
            <Icon name="content_copy" />
          </Button>
          <Button color="danger" variant="link" square aria-label="Delete API key" @click="clickDelete(key)">
            <TwinIcon icon="Delete" />
          </Button>
        </Row>
      </Card>
    </Grid>
  </template>
  <Spinner v-else />

  <Row justify="end">
    <Button @click="readmeOpen = true">
      <TwinIcon icon="Info" />
      API documentation
    </Button>
    <Button @click="createKeyOpen = true">
      <TwinIcon icon="Plus" />
      New key
    </Button>
  </Row>

  <LargeModalLayout :open="createKeyOpen" title="New API key" @close="createKeyOpen = false">
    <template #content>
      <FormItem label="Name (optional)">
        <TextInput v-model="createData.name" />
      </FormItem>
    </template>

    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button color="primary" variant="solid" @click="clickCreate()">Create key</Button>
    </template>
  </LargeModalLayout>

  <OrganizationApiReadme v-model:open="readmeOpen" />
</template>
