// Generic lodash-like utils

import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'

export function useUtils() {
  function truncate(str: string, length: number) {
    return str.length > length ? str.slice(0, length - 1) + '…' : str
  }

  function debounce<T>(func: () => T | Promise<T>, opts?: { delayMs?: number }) {
    const timeout = ref<ReturnType<typeof setTimeout>>()
    const isPending = computed(() => timeout.value != null)

    function cancel() {
      clearTimeout(timeout.value)
      timeout.value = undefined
    }

    function trigger() {
      cancel()
      const delayMs = opts?.delayMs ?? 500
      timeout.value = setTimeout(() => {
        timeout.value = undefined
        asyncContext(async () => {
          await func()
        })
      }, delayMs)
    }

    return { trigger, cancel, isPending }
  }

  function asyncContext<T>(fn: () => T | Promise<T>) {
    // For using await from within sync functions with error handling
    const result = ref<T>()
    const error = ref<unknown>()

    watchEffect(() => {
      if (error.value) throw error.value
    })

    const promise = fn()
    if (promise instanceof Promise) {
      promise.then((res) => (result.value = res)).catch((e) => (error.value = e))
    }

    return result
  }

  function camelCaseToReadable(camelCaseString: string) {
    if (!camelCaseString) return camelCaseString
    return camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())
  }

  function firstCharToUpperCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return { truncate, debounce, asyncContext, camelCaseToReadable, firstCharToUpperCase }
}

export function usePointer() {
  const position = ref({ x: 0, y: 0 })

  function pointermove(e: PointerEvent) {
    position.value = { x: e.clientX, y: e.clientY }
  }

  onMounted(() => {
    document.addEventListener('pointermove', pointermove)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('pointermove', pointermove)
  })

  return { position }
}
