import { useApiClient } from '@/api'
import type {
  BlockConfigDetailsFragment,
  BlockConfigInputSchema,
  WorkflowBlockItemFragment,
  WorkflowDetailsFragment,
} from '@/generated/sdk'
import { ref } from 'vue'

const workflow = ref<WorkflowDetailsFragment>()

export function useWorkflowDetails() {
  const { client } = useApiClient()

  async function loadWorkflow(workflowId: string) {
    if (workflowId === workflow.value?.id) return
    workflow.value = undefined
    const response = await client.workflowDetails({ id: workflowId })
    workflow.value = response.workflow[0]
  }

  async function updateWorkflow(update: {
    name?: string
    isPublic?: boolean
    description?: string
    editorState?: Record<string, unknown>
  }) {
    if (!workflow.value) throw new Error('Workflow not loaded')
    Object.assign(workflow.value, update)
    await client.updateWorkflow({ input: { ...update, id: workflow.value.id } })
  }

  async function updateBlock(
    block: WorkflowBlockItemFragment,
    update: { name?: string | null; condition?: string | null },
  ) {
    Object.assign(block, update)
    await client.updateWorkflowBlock({ input: { ...update, id: block.id } })
  }

  async function updateBlockConfig(config: BlockConfigDetailsFragment, update: BlockConfigInputSchema) {
    if (!workflow.value) throw new Error('Workflow not loaded')
    const response = await client.updateBlockConfig({ input: { ...update, id: config.id } })
    const index = workflow.value.blockConfigs.findIndex((b) => b.id === config.id)
    if (index > -1) workflow.value.blockConfigs[index] = response.updateBlockConfig
    const block = workflow.value.workflowBlocks.find((b) => b.blockConfig.id === config.id)
    if (block) block.blockConfig = response.updateBlockConfig
  }

  async function deleteBlock(block: WorkflowBlockItemFragment) {
    if (!workflow.value) throw new Error('Workflow not loaded')
    await client.deleteWorkflowBlock({ workflowBlockId: block.id })
    const index = workflow.value.workflowBlocks.findIndex((b) => b.id === block.id)
    if (index === -1) throw new Error('Block not found')
    workflow.value.workflowBlocks.splice(index, 1)
  }

  async function deleteConnection(from: WorkflowBlockItemFragment, to: { id: string }) {
    await client.removeNextBlock({ blockId: from.id, nextBlockId: to.id })
    for (const block of workflow.value?.workflowBlocks ?? []) {
      if (block.id === from.id) {
        block.nextBlocks = block.nextBlocks?.filter((b) => b.id !== to.id)
      } else if (block.id === to.id) {
        block.previousBlocks = block.previousBlocks?.filter((b) => b.id !== from.id)
      }
    }
  }

  async function connectBlocks(from: WorkflowBlockItemFragment, to: { id: string; condition?: string | null }) {
    const result = await client.updateWorkflowBlock({
      input: { id: from.id, nextBlocks: [{ id: to.id, condition: to.condition }] },
    })
    from.nextBlocks = result.updateWorkflowBlock.nextBlocks
    for (const block of workflow.value?.workflowBlocks ?? []) {
      if (block.id === to.id && !block.previousBlocks?.some((b) => b.id === from.id)) {
        block.previousBlocks ??= []
        block.previousBlocks.push({ id: from.id, condition: to.condition })
      } else if (block.id === from.id && !block.nextBlocks?.some((b) => b.id === to.id)) {
        block.nextBlocks ??= []
        block.nextBlocks.push({ id: to.id, condition: to.condition })
      }
    }
  }

  async function deleteWorkflow() {
    if (!workflow.value) throw new Error('Workflow not loaded')
    await client.deleteWorkflow({ workflowId: workflow.value.id })
  }

  return {
    workflow,
    loadWorkflow,
    updateWorkflow,
    updateBlock,
    updateBlockConfig,
    deleteBlock,
    deleteConnection,
    connectBlocks,
    deleteWorkflow,
  }
}
